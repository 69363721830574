import styled from 'styled-components';

export const Container = styled.div`
  /* height: 100vh; */
  background-color: #dddd; 

  @media (min-width: 1800px) {
    height: 100vh;
  }
`;

export const Header = styled.div`
  padding: 30px 0;
  background: #222831;
`;

export const HeaderContent = styled.div`
  display: flex;
  max-width: 1120px;
  margin: 0 auto;
  align-items: center;

 img {
    height: 80px;
  }

  button {
    margin-left: auto;
    background: transparent;
    margin-right: 20px;
    border: 0;

    svg {
      color: #81878E;
      width: 30px;
      height: 30px;
      transition: all 0.5s;

      &:hover {
        color: #ff0000;
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #ffffff;
    }

    strong {
      color: #1c84b0;
    }

    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 350px) {
    margin-left: 30px;
  }
`;

export const Calender = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em;  
`;

export const AllGraph = styled.section`
  padding-top: 2em;
  padding-bottom: 2em;

  @media (min-width: 750px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  @media (min-width: 1080px) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }

  @media (min-width: 1500px) {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(5, minmax(100px, 1fr));
  }
`