import React from 'react';
import 'dotenv/config'

import { Switch } from 'react-router-dom';

import Route from './Route';

// import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => (
  <Switch>
    {/* <Route path="/" exact component={Login} /> */}

    {/* <Route path="/dashboard" exact component={Dashboard} isPrivate/> */}
    <Route path="/" exact component={Dashboard}/>
  </Switch>
);

export default Routes;