import React, { useState, useCallback, useEffect } from 'react';
import { FiPower } from 'react-icons/fi';
import { FaUserTie, FaUser } from 'react-icons/fa';
import { IoStorefrontSharp } from 'react-icons/io5';
import { IoIosCut } from 'react-icons/io';
import { SiCashapp } from 'react-icons/si';
import { Modal } from 'react-bootstrap'
import Chart from "react-google-charts";
import ptBr from 'date-fns/locale/pt-BR'
import { ImpulseSpinner } from 'react-spinners-kit';

import TextField from '@material-ui/core/TextField';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';

import {
  Container,
  Header,
  HeaderContent,
  Profile,
  Calender,
  AllGraph,
} from './styles';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import api from '../../services/api';

import Button from '../../components/Button';
import Graph from '../../components/Graph';

interface IUser {
  id: string;
  name: string;
  email: string;
}

interface IGetUser {
  users: IUser[];
  total: string;
}

interface IAppointment {
  id: string;
  price: string;
  status: string;
}

interface IGetAppointment {
  appointments: IAppointment[]
  total: string;
  amount: string;
}

const Dashboard: React.FC = () => {
  const [showGraph, setShowGraph] = useState(false);

  const [users, setUsers] = useState<IGetUser>({} as IGetUser);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [providers, setProviders] = useState<IGetUser>({} as IGetUser);
  const [loadingProviders, setLoadingProviders] = useState(false);

  const [barbershops, setBarbershops] = useState<IGetUser>({} as IGetUser);
  const [loadingBarbershops, setLoadingBarbershops] = useState(false);

  const [appointments, setAppointments] = useState<IGetAppointment>({} as IGetAppointment);
  const [loadingAppointments, setLoadingAppointments] = useState(false);

  const [dates, setDates] = React.useState<DateRange<Date>>([null, null]); 

  const { signOut } = useAuth();
  const { addToast } = useToast();  

  const handleGetUsers = useCallback(async (categoryName: string, startDate: Date, endDate: Date) => {
    try {
      if(categoryName === 'user') setLoadingUsers(true)

      else if(categoryName === 'provider') setLoadingProviders(true)

      else setLoadingBarbershops(true)

      const response = await api.get<IUser[]>('/users/range-date',{
        params: {
          categoryName, startDate, endDate
        }
      });

      const total = String(response.headers.total);

      if(categoryName === 'user') 
        setUsers({
          users: response.data,
          total
        })
      

      else if(categoryName === 'provider')
        setProviders({
          users: response.data,
          total
        })
      

      else 
        setBarbershops({
          users: response.data,
          total
        })    

    } catch (err) {
      console.log(err);

      addToast({
        title: 'Erro ao buscar os usuarios',
        type: 'error'
      })    
    } finally {
      if(categoryName === 'user') setLoadingUsers(false)

      else if(categoryName === 'provider') setLoadingProviders(false)

      else setLoadingBarbershops(false)
    }
  },[addToast]);

  const handleGetAppointments = useCallback(async (startDate: Date, endDate: Date) => {
    try {
      setLoadingAppointments(true)

      const response = await api.get<IAppointment[]>('/appointments/range-date',{
        params: { startDate,endDate }
      })

      setAppointments({ 
        appointments: response.data,
        amount: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(response.headers.amount),
        total: String(response.headers.total)
      })
    } catch (err) {
      addToast({
        title: 'Erro ao buscar os serviços',
        type: 'error'
      }) 
    } finally {
      setLoadingAppointments(false)
    }
  },[addToast])

  useEffect(() => {
    const startDate = new Date(Date.now());
    const endDate = new Date(Date.now());
    endDate.setDate(endDate.getDate() - 7);

    handleGetUsers('user', startDate, endDate);
    handleGetUsers('provider', startDate, endDate);
    handleGetUsers('barbershop', startDate, endDate);
    handleGetAppointments(startDate, endDate)

  },[handleGetUsers, handleGetAppointments]);


  const handleDates = useCallback((dates: DateRange<Date | null>) => {
    setDates(dates);
  },[]);

  const handleConfirmDates = useCallback(async (dates: DateRange<Date | null>) => {
    if(dates[0] && dates[1]){
      const { startDate, endDate } = {
        startDate: dates[0],
        endDate: dates[1]
      }

      try {
        await Promise.all(
          [ 
            handleGetUsers('user', startDate, endDate),
            handleGetUsers('provider', startDate, endDate),
            handleGetUsers('barbershop', startDate, endDate),
            handleGetAppointments(startDate, endDate)
          ]
        )
      } catch (err) {
        console.log(err)
        addToast({
          title: 'Erro ao buscar',
          type: 'error'
        }) 
      }      
    }      
  },[handleGetUsers, handleGetAppointments, addToast])

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Profile>
            <img
              src={
                `https://ui-avatars.com/api/?name=Admin iHair`
              }
              alt="Admin"
            />
            <div>
              <span>Bem-Vindo,</span>
              <strong>Admin iHair</strong>
            </div>
          </Profile>

          <button type="button" onClick={signOut}>
            <FiPower />
          </button>
        </HeaderContent>
      </Header>  
      
      <Calender>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBr} >
          <DateRangePicker
            startText="Data inicial"
            endText="Data final"
            inputFormat="dd/MM/yyyy"
            toolbarTitle="Selecione uma data"
            showDaysOutsideCurrentMonth
            cancelText="Cancelar"
            okText="Confirmar"           
            value={dates}            
            onAccept={handleConfirmDates}
            onChange={handleDates}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField variant='standard' {...startProps} />
                <Box sx={{ mx: 2 }}> até </Box>
                <TextField variant='standard' {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>        
      </Calender>

      <AllGraph>
        <Graph 
          icon={FaUser} 
          title="Cadastro de clientes" 
          value={users.total} 
          loading={loadingUsers} 
        />      
        <Graph 
          icon={FaUserTie} 
          title="Cadastro de barbeiros" 
          value={providers.total} 
          loading={loadingProviders} 
        />      
        <Graph 
          icon={IoStorefrontSharp} 
          title="Cadastro de barbearias" 
          value={barbershops.total} 
          loading={loadingBarbershops} 
        />      
        <Graph 
          // onClick={() => setShowGraph(true)}
          icon={IoIosCut} 
          title="Serviços cadastrados" 
          value={appointments.total}
          loading={loadingAppointments}
         />      
        <Graph 
          icon={SiCashapp} 
          title="Receita Bruta" 
          value={appointments.amount} 
          loading={loadingAppointments}
        />    
      </AllGraph>
      
      <Modal show={showGraph} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header>
          <Modal.Title style={{margin: 'auto'}}>
            Grafico dos serviços
          </Modal.Title>
        </Modal.Header>
          <Chart
            className="chart"
            chartType="PieChart"
            loader={<ImpulseSpinner frontColor="#222831" backColor="#16141462" />}
            data={[
              ['Pizza', 'StatusServices'],
              ['Work', 11],
              ['Eat', 2],
              ['Commute', 2],
              ['Watch TV', 2],
              ['Sleep', 7],
            ]}          
          />
        <Modal.Footer>
          <Button onClick={() => setShowGraph(false)} >Fechar</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default Dashboard;
