import styled from 'styled-components';

export const Container = styled.button`
  width: 350px;
  height: 350px;
  display: flex;
  background-color: #fff;
  margin: auto;
  margin-top: 1em;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  
  div {
    margin: auto;

    h3 {    
      padding-top: 11%;
      font-weight: bold;
    }

    svg {
      margin-top: 18%;      
    }    
    
    p {
      padding-top: 5%;
      font-size: 4em;
      font-weight: bold;
    }

    div {
      margin-top: 15%;
      margin-bottom: 18%;    
    }
  }

  @media (max-width: 350px) {
    width: 300px;
    height: 300px;
  }
  
`;
