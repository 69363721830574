import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus{
  outline: 0;
}

html, body, #root {
  height: 100%;
}

body {
  background: #222831;
  color: #2e2e2e;
  transition: all 0.25s linear;
  -webkit-font-smoothing: antialiased;

  .chart {
      width: 400px;
      height: 300px;
      margin-left: -13%;

      svg rect {
        opacity: 0;
      }
    
    text {
      font-size: 12px;
    }

    @media (min-width: 370px) {
      margin-left: 0;

      text {
        font-size: 12px;
      }
    }

    @media (min-width: 700px) {      
      width: 600px;
      height: 400px;

      text {
        font-size: 16px;
      }
    }

    @media (min-width: 1000px) {      
      width: 900px;
      height: 600px;

      text {
        font-size: 20px;
      }
    }
  }
}

body, input, button {
  font: 14px 'Roboto', sans-serif;
}

footer {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

a {
  text-decoration: none;
  color: #2e2e2e;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.css-2b097c-container {
  margin-top: 10px;

  .css-b8ldur-Input {
    margin-top: 10px;
  }
}

.css-14jk2my-container {
  margin-top: 10px;
}

.table td, .table th {
  vertical-align: middle;
  border-top: 0;
}



.form {
  background: #fff;
  border-radius: 6px;
  padding: 25px;
  margin-top: 25px;
}
`;
