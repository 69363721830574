import React, { ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import { ImpulseSpinner } from 'react-spinners-kit';

import { Container } from './styles';

type IProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  icon: React.ComponentType<IconBaseProps>;
  value: string;
  loading: boolean;
};

const Graph: React.FC<IProps> = ({icon: Icon, value, title, loading, ...rest}) => {
  return (
    <Container type="button" {...rest}>
      <div>
        <h3>{title}</h3>
        <Icon size={50} /> 
        {loading ? 
          <ImpulseSpinner frontColor="#222831" backColor="#16141462" /> : 
          <p>{value}</p>
        }       
      </div>
    </Container>
  )
}

export default Graph;